.App {
  text-align: center;
}
body {
  background-color: black;
  color: white !important;
  box-sizing: border-box;
}

#root {
  background-color: white;
  color: black !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100%;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
}

#background-image {
  background-image: url("../HeroCalculator.png");
  width: 100vw;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#vivint-energy-img {
  margin: 15px;
  background-image: url("../vivint\ energy\ image.png");
  width: 100vw;
  height: 65px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.bottom-disclaimer {
  color: red
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-ai-fs {
  align-items: flex-start;
}

.flex-ai-center {
  align-items: center;
}

.flex-jc-center {
  justify-content: center;
}

.flex-jc-fs {
  justify-content: flex-start;
}

.flex-jc-se {
  justify-content: space-evenly;
}

.flex-jc-sb {
  justify-content: space-between;
}

.width50 {
  width: 50% !important;
}

.width60 {
  width: 60%;
}

.width80 {
  width: 80%;
}

.width70 {
  width: 70%;
}

.width100 {
  width: 100% !important;
}

.width50 {
  width: 50%;
}

.width30 {
  width: 30%;
}

.width20 {
  width: 20%;
}

.width90 {
  width: 90%;
}

.width85 {
  width: 85%;
}

.margin-right0 {
  margin-right: 0 !important;
}

.margin-right10 {
  margin-right: 10px !important;
}

.color-white {
  color: white;
}

.color-black {
  color: black !important;
}

.border-white {
  border: 1px solid white;
}

.border-gray {
  border: 2px solid #222;
}

.border-light-gray {
  border: 2px solid #777;
}

.border-round {
  border-radius: 25px !important;
}

.margin-top {
  margin-top: 35px !important;
}

.padding15 {
  padding: 15px;
}

.padding5 {
  padding: 5px;
}

.border-box {
  box-sizing: border-box;
}

.margin0 {
  margin: 0;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.bg-gray {
  background: #222;
}

.bold {
  font-weight: bold;
}

.css-1yjjitx-MuiSwitch-track{
  background-color: white !important;
}

/******** Patch Code *****/
#root{
  background-color: #000;
}

h1, h2, h3, h4, p{
  color: #FFF;
}

#background-image{
  margin-bottom: 2rem;
}

/******** Buttons *****/
.MuiButtonBase-root.MuiButton-root{
  background-color: #000;
  color: #FFF;
  border: solid 2px #FFF;
  border-radius: 60px;
  opacity: 0.7;
}
.MuiButtonBase-root.MuiButton-root:hover{
  background-color: #FFF;
  border: solid 2px #FFF;
  color: #000;
}

/* Selected Button */
.MuiButtonBase-root.MuiButton-root.MuiButton-contained{
  opacity: 1;
  background-color: #FFF;
  border: solid 2px #FFF;
  color: #000;
}
/******** END Buttons *****/



/******** Input Fields *****/

#nppw-input-div .MuiInputBase-input.MuiOutlinedInput-input {
  width: 50px !important;
  padding: 3px;
}

#nppw-input-div .MuiOutlinedInput-notchedOutline {
  border-radius: 0%;
}

.MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
  border-width: 2px;
  opacity: 0.7;
  border-radius: 60px;
}
#calculator .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
  border-color: #FFF;
}
#calculator .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #FFF;
  opacity: 1;
}
#calculator .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #FFF;
  opacity: 1;
}
#calculator .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined{
  color: #FFF;
}
#calculator .MuiFormControl-root.MuiTextField-root label,
#calculator .MuiFormControl-root.MuiFormControl-fullWidth.css-q8hpuo-MuiFormControl-root label{
  color: #FFF;
  padding: 0.2rem;
}
#calculator .MuiSelect-nativeInput,
#calculator .MuiInputBase-input.MuiOutlinedInput-input{
  color: #FFF;
}
#calculator .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
  color: #FFF;
  margin-left: 2rem;
}
/******** END Input Fields *****/



/******** Slider *****/
.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium{
  color: #FFF;
}
/******** END Slider *****/



/******** Table *****/
.MuiTable-root{
  border: solid 2px #FFF;
}

.MuiTable-root{
  min-width: unset !important;
}
.MuiTable-root thead tr th,
.MuiTable-root tbody tr th,
.MuiTable-root tbody tr td{
  padding: 3px 5px;
  text-align: center;
}
/******** END Table *****/

.absolute {
  position: absolute;
}

.top-20 {
  top: 20px;
}

.right-35 {
  right: 35px;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width:768px){
  .width70 {
    width: 100%;
  }

  h3{margin: 0.5rem 0}
}

@media only screen and (max-width: 770px){
  #root{
    padding: 0 15px 20px 15px;
  }
  #background-image{
    height: 200px;
    margin-bottom: 0;
  }
  h1{margin-bottom: 0;}
  h3{margin: 0.5rem 0;}
  /* h1, h2, h3, h4, h5, h6, p{
    text-align: center;
  } */
  .flex-row{
    /* flex-direction: column;
    align-items: center; */
    /* flex-wrap: wrap; */
  }
  /* .flex-column{
    margin-top: 1rem;
  } */
  .flex-row h3{
    margin: 0;
  }
  .flex-row button{
    width: fit-content;
    margin-bottom: 1rem;
    margin-right: 1rem;
    font-size: 12px;
    margin-right: 0.25rem !important;
  }
  .flex-jc-sb button{
    margin-right: 1rem;
  }
}
